import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { AddonsFieldsFragment } from './queries/useProductWithAddonsQuery/__generated__/useProductWithAddonsQuery'
import { GetUpsellProduct_productLookup_products } from './queries/getUpsellProduct'

enum Dependencies {
  DIRECT_SMILE = 'DIRECT_SMILE',
}

export type ImageType = {
  url: string
}

export type Department = DepartmentsEnum

export type Price = {
  currencyCode: string
  centAmount: number
  fractionDigits: number
}

export type Capabilities = {
  video: boolean
}

export type ProductVariantBundle = {
  description: string
  price: Price
  discountPercentage: number
  size: number
}

export type ProductVariantLocation = {
  city: string
  postcode: string
}

type VariantContentModuleDetails = {
  __typename: 'ProductVariantContentModuleDetails'
  items: { key: string; value: string; title: string }[]
}

type VariantContentModule = VariantContentModuleDetails

type VariantContent =
  | {
      __typename: 'ProductVariantContent'
      modules: VariantContentModule[]
    }
  | { __typename: 'ProductVariantContentNotFoundError' }

export type SizedImage = {
  jpgUrl: string
}

export type ProductImage = {
  thumb: SizedImage
  small: SizedImage
  medium: SizedImage
  large: SizedImage
  extraLarge: SizedImage
  original: SizedImage
}
export type ProductImages = {
  images: ProductImage[]
}

export type Variant = {
  title: string
  subtitle?: string | null
  price: Price
  fullPrice: Price
  discountedPercentage?: number | null
  key: string
  inStock: boolean
  minimumQuantity: number
  bundles?: ProductVariantBundle[]
  sku: string | null
  dimensions?: {
    description: string
  } | null
  addons?: AddonsFieldsFragment[] | null
  capabilities?: Capabilities | null
  content?: VariantContent
  locations?: ProductVariantLocation[] | null
}

export type MasterVariant = Variant & {
  images: ImageType[]
  masterImage: ImageType
  productImages?: ProductImages
}

export type Rating = {
  count?: number
  score: number
}

export enum ProductPillType {
  ECO = 'ECO',
  MARKETING = 'MARKETING',
  PRICE = 'PRICE',
  UNIQUE_SELLING_POINT = 'UNIQUE_SELLING_POINT',
  PLUS = 'PLUS',
}

export type ProductPill = {
  displayLabel: string
  displayVariant: ProductPillType
}

export type Category = {
  id: number
  name: string
  slug: string
  department: Department
}

export type ProductInfoProduct = {
  id: string
  title: string
  description?: string
  variants: Variant[]
  dependencies?: (Dependencies | null)[]
  category: Category
  customisable: boolean
  isLandscape?: boolean | undefined
  masterVariant: MasterVariant
  rating?: Rating | null
  slug: string
  editableTextFieldCount?: number
  photoUploadCount?: number
  hasAugmentedReality?: boolean
  productIndex?: number
  relatedTerms?: { terms: string[] } | null
  isFavourite?: boolean
  primaryProductPill?: ProductPill | null
  productPills?: ProductPill[]
  isSponsored: boolean
  internallyPromoted?: boolean
}

export type Product = {
  id: string
  slug: string
  title: string
  customisable: boolean
  category: {
    id: number
    slug: string
    name: string
    department: DepartmentsEnum
  }
  masterVariant: {
    title: string
    images: {
      url: string
    }[]
    price: {
      centAmount: number
      fractionDigits: number
      currencyCode: string
    }
    inStock: boolean
  }
  rating?: {
    score: number
    count?: number
  } | null
  isFavourite?: boolean
}

export type FormattedPrice = {
  displayPrice: string
  itemPrice?: string
  description: string
  currencyCode: string
  currencySymbol: string
  centAmount: number
  fractionDigits: number
}

export type FormattedVariant = {
  label: string
  price: FormattedPrice
  fullPrice: FormattedPrice
  discountedPercentage?: number | null
  value: string
  selected: boolean
  inStock: boolean
  minimumQuantity: number
  sku: string
  bundles?: ProductVariantBundle[]
}

export type FormattedVariantCategory = {
  label: string
  variants: FormattedVariant[]
  price: FormattedPrice
}

export type AddedToBasketEvent = {
  product: ProductInfoProduct
  productIndex: number
  variant: Variant
  quantity: number
  index?: number
  selectedAddon?: AddonsFieldsFragment | null
  productsLength?: number
  buttonActionName?: ButtonActionName | undefined
  requiresEditingValue?: boolean
  componentName?: string
  trackPersonaliseEvent?: boolean
  redirectUser?: boolean
  giftXSellTrackingData?: GiftXSellTrackingData
}

export type GiftXSellTrackingData = {
  currentIndex: number
  totalProducts: number
}

export type RecommendationClickedEvent = {
  product: ProductInfoProduct
}

export type ProductList = {
  title: string
  position: string | number
  totalNumberOfLists: string | number
  tabbedModuleTitle?: string
}

export enum ProductItemEventEnum {
  VIEW_ITEM = 'view_item',
  SELECT_ITEM = 'select_item',
}

export enum AddonItemEventEnum {
  SELECT_ITEM = 'select_item',
}

export enum ButtonActionName {
  PERSONALISE = 'personalise',
  ADD_TO_BASKET = 'add to basket',
  CREATE_GROUP_CARD = 'create group card',
}

export enum AccordionSection {
  DESCRIPTION = 'description-section',
  DELIVERY = 'delivery-section',
  REVIEWS = 'reviews-section',
}

export const AccordionSectionNameLookup = {
  [AccordionSection.DESCRIPTION]: 'product details',
  [AccordionSection.DELIVERY]: 'delivery options',
  [AccordionSection.REVIEWS]: 'reviews',
}

export enum AccordionSectionAction {
  OPEN = 'open',
  CLOSE = 'close',
}

export type Recommendation = {
  url: string
  title: string
  id: string
  masterVariant: {
    title: string
    inStock: boolean
    price: { centAmount: number; currencyCode: string }
  }
  category: { department: DepartmentsEnum; name: string }
}

export type RequiresEditing = {
  loading: boolean
  value: boolean
}

export type ProductInfoTrackingData = {
  pageLocation: string
  product: {
    id: string
    index: number
    title: string
    variantTitle: string
    slug: string
    price: number
    categoryName: string
    categorySlug: string
    addonLoaded?: boolean
    categoryDepartment: string | undefined
    inStock: boolean
  }
}

export type PillVariant =
  | 'default'
  | 'price'
  | 'marketing'
  | 'eco'
  | 'plus'
  | 'sponsored'

export type UpsellProduct = GetUpsellProduct_productLookup_products

type ProductClickedEventVariant = {
  price: Price
  title: string
  key: string
}

export type ProductClickedEvent = {
  product: ProductInfoProduct
  variant: ProductClickedEventVariant
  index?: number
}

export type Filter = {
  group: string
  facetKey: string
}

export enum PageTypeEnum {
  ADD_A_CARD = 'add a card',
  ADD_A_GIFT = 'add a gift',
  CARD_OPTIONS = 'card options',
  PDP = 'product details',
  FAVOURITES = 'favourites',
  HOME = 'home',
  GALLERY = 'gallery',
  LANDING = 'landing',
  SEARCH = 'search',
  GIFT_FINDER_RESULTS = 'gift finder results',
  REMINDERS = 'reminders landing',
  BASKET = 'basket',
  DYNAMIC = 'dynamic',
}

export type ProductContext = {
  location: string
  pageType: PageTypeEnum
  flags: Record<string, boolean>
  filters?: Filter[]
  totalNumberOfProducts?: number
}

export type PromoTileCta = {
  url?: string | undefined
  title: string
  onClick: (() => void) | undefined
}

export type PromoTileData = {
  title: string
  description: string
  sponsored: boolean
  cta: PromoTileCta | undefined
  image: {
    src: string
    description: string | undefined
  }
}

export type TilesPerRow = {
  xs: number
  sm: number
  md: number
  lg: number
  xl: number
  xxl: number
}

export type CutOff = {
  time: string
  message: string
}

export type DispatchCutOff = {
  weekdays: CutOff
  weekendsAndBankHolidays: CutOff
}
