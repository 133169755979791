export const CUSTOMER_SATISFACTION_TILE = {
  'customer_satisfaction_tile.question': {
    'en-GB': 'Are these results what you expected?',
    'nl-NL': 'Zijn deze resultaten wat je ervan verwacht had?',
  },
  'customer_satisfaction_tile.thankyou': {
    'en-GB': 'Thank you for your feedback!',
    'nl-NL': 'Bedankt voor je feedback!',
  },
  'customer_satisfaction_tile.close': {
    'en-GB': 'Close',
    'nl-NL': 'Sluiten',
  },
  'customer_satisfaction_tile.yes': {
    'en-GB': 'Yes',
    'nl-NL': 'Ja',
  },
  'customer_satisfaction_tile.no': {
    'en-GB': 'No',
    'nl-NL': 'Nee',
  },
}
